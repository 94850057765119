@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: normal;
  font-display: swap;
  src: url("./fonts/Inter-Regular.woff2") format("woff2"),
        url("./fonts/Inter-Regular.woff") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 500;
  font-display: swap;
  src: url("./fonts/Inter-Medium.woff2?v=3.13") format("woff2"),
       url("./fonts/Inter-Medium.woff?v=3.13") format("woff");
       
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 900;
  font-display: swap;
  src: url("./fonts/Inter-Black.woff2?v=3.13") format("woff2"),
       url("./fonts/Inter-Black.woff?v=3.13") format("woff");
}
