.menu {
  /* width: 190px; */
  max-width: 180px;
  min-height: 100vh;
  color: #fff;
  background-color: rgb(32,32,32);
}

.menu__container {
  padding-left: 10px;
  padding-top: 80px;
  position: sticky;
  top: 0;
}

.menu__heading {
  font-weight: 500;
  margin: 0 0 80px;
  cursor: pointer;
  font-size: 32px;
  display: block;
}

.menu__ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu__ul > li {
  margin-bottom: 30px;
  display: flex;
  align-items: center;
}

.menu__ul > li:hover > .menu__li {
  color: #fff;
}

.menu__li:hover > .menu__icon {
  opacity: 1;
  cursor: pointer;
}

.menu__li {
  text-decoration: none;
  color: #a8a7a7;
  transition: color 0.3s;
  display: flex;
  align-items: center;
}

.menu__li:hover {
  color: #fff;
}

.menu__icon {
  width: 30px;
  margin-right: 10px;
  opacity: 0.6;
  transition: all 0.3s;
}

.menu__li_active {
  color: #fff;
}

.menu__li_active > .menu__icon {
  opacity: 1;
}

.menu__heading_ts {
  display: none;
  width: 60px;
  height: 60px;
  background: rgb(83 79 79 / 60%);
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-bottom: 50px;
  cursor: pointer;
  transition: all 0.3s;
}

.menu__heading_ts:hover {
  background: rgb(83 79 79);
}

.menu__heading_ts > p {
  margin: 0;
  font-size: 27px;
}

.menu_inactive {
  display: none;
}

@media (max-width: 884px) {
  .menu__li {
    content: '';
    font-size: 0;
  }

  .menu__heading_ts {
    display: flex;
  }

  .menu__heading {
    display: none;
  }

  .menu__icon {
    width: 40px;
    margin-right: 0;
  }

  .menu__container {
    padding-left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .menu__ul {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .menu {
    /* width: 11vw; */
    min-width: 88px;
  }
}

@media (max-width: 580px) {
  .menu {
    width: 100%;
    max-width: 100vw;
    min-height: 70px;
    display: flex;
    align-items: center;
  }

  .menu_inactive {
    display: none;
  }

  .menu__container {
    padding: 0;
    flex-direction: row;
  }

  .menu__ul {
    flex-direction: row;
    margin-left: 10px;
  }

  .menu__heading_ts {
    margin: 0 0 0 15px;
  }

  .menu__ul > li {
    margin-bottom: 0;
    margin-left: 35px;

  }

}

