.stat {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  /* visibility: hidden; */
  top: 7%;
  transition: all 0.3s;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  animation: slidin 0.3s;
}


.stat__main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 30px 0 30px;
  background: linear-gradient(to right, #9f9b9b, #e6e5e5);
  border-radius: 22px;
  width: 200px;
  min-height: 77px;
}

.stat__heading {
  margin: 0 0 5px;
  font-weight: 500;
  font-size: 30px;
}

.stat__parag {
  margin: 0;
}

.stat__tags {
  /* box-shadow: 0px 0px 7px 0px rgb(160 159 159); */
  padding: 15px;
  /* border-radius: 15px; */
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.stat__tags-container {
  display: grid;
  grid-template-columns: auto auto;
}

.stat__tag {
  font-size: 16px;
  font-weight: 100;
  margin: 0;
}

.stat__progress {
  width: 290px;
  height: 13px;
  background: #168cdd2b;
  grid-column: 1/3;
  border-radius: 10px;
  margin-top: 5px;
}

.stat__progress_filled {
  width: 50%;
  height: 100%;
  background: rgb(22 140 221);
  border-radius: 10px;
}

.stat__value {
  font-size: 15px;
  justify-self: end;
  color: #168cdd;
}

.stat__works {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin: 10px 55px 0 50px; */
  box-shadow: 0px 0px 7px 0px rgb(160 159 159);
  border-radius: 10px;
  padding: 10px;
  height: fit-content;
}

/* .stat__works:after {
  content: '';
  border: 1px solid red;
} */

.stat__views {
  /* display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 0px 7px 0px rgb(160 159 159);
  border-radius: 10px;
  padding: 10px;
  height: fit-content; */
  /* margin: 10px 0; */
}

.stat__card {
  width: 220px;
  position: relative;
  margin-bottom: 10px;
}

.stat__img {
  width: 100%;
  display: block;
  height: 100px;
  object-fit: cover;
  border-radius: 17px;
}

.stat__overlay {
  width: 100%;
  height: 100%;
  background: linear-gradient(88deg, #000000d4, #00000061);
  position: absolute;
  top: 0;
  border-radius: 17px;
}

.stat__info {
  margin: 8% 0 0 8%;
}

.stat__raiting {
  font-size: 23px;
  margin: 0;
  color: #fff;
}

.stat__views-count {
  color: #a9a6a6;
  margin: 0;
}

span {
  font-size: 13px;
}

@keyframes slidin {
  from {
    top: -100%;
  }
  to {
    top: 7%;
  }
}

@media (max-width: 580px) {
  .stat {
    top: 15%;
  }
}
