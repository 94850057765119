.page {
  font-family: 'Inter', Arial, Helvetica, Sans-serif;
  background: rgb(102, 140, 123, 0.1);
  /* color: #fff; */

}

.page__wrapper {
  /* max-width: 1280px; */
  min-height: 100vh;
  /* padding: 0 20px; */
  margin: auto;
  display: flex;
}

.link {
  transition: opacity 0.3s linear;
}

.link:hover {
  opacity: 0.6;
  cursor: pointer;
}

.body_unscrolled {
  overflow: hidden;
}

a {
  color: #fff;
  text-decoration: none;
}


@media (max-width: 580px) {
  .page__wrapper {
    flex-direction: column;
    align-items: center;
  }

}
